@import "../../../styles/variables/index.scss";

.item_default {
  --gx-spacing-xx-small: 13px;
  --gx-spacing-x-large: 10px;
  --gx-color-primary-95: var(--gx-color-list-hover);
  &::part(base) {
    align-items: center;
  }
  &::part(checked-icon) {
    display: none;
  }
  &::part(label) {
    @include font(14);
  }
}
.item_help-page {
  --gx-spacing-xx-small: 13px;
  --gx-spacing-x-large: 10px;
  --gx-color-primary-95: var(--gx-color-list-hover);
  &::part(base) {
    align-items: center;
    white-space: normal;
  }
  &::part(checked-icon) {
    display: none;
  }
  &::part(label) {
    @include font(14);
  }
}
