@import '../../../styles/variables/index.scss';

.dropdown_default {
  --gx-border-radius-medium: 6px;
  width: max-content;
  margin-left: auto;
  &::part(panel) {
    background-color: var(--gx-color-white);
    border: none;
    padding: 10px 0;
    box-shadow: var(--gx-dropdown-shadow);
  }
}

.dropdown_notification {
  width: max-content;
  &::part(panel) {
    background-color: var(--gx-color-white);
    border: none;
    padding: 10px 0;
    box-shadow: var(--gx-dropdown-shadow);
    width: 415px;
    max-height: 100%;
    @include mobile {
      width: 100%;
    }
  }
}

.dropdown_input {
  --gx-border-radius-medium: 6px;
  width: max-content;
  margin-left: auto;
  &::part(panel) {
    background-color: var(--gx-color-white);
    border: none;
    padding: 10px 0;
    box-shadow: var(--gx-dropdown-shadow);
  }
  &[open]:not([open="false"]) {
    &::part(trigger) {
      // transform: scale(1, -1);
      transform: rotate(-180deg);
      transition: var(--gx-transition-short) transform ease;
    }
  }
}
