@import '../../../styles/variables/index.scss';

.page_loader {
  @include mobile {
    position: relative;
    padding: 50px;
  }
}

.list_loader {
  @include tablet-md {
    position: relative;
    height: 50px;
  }
}