.panel_add {
  height: 60px;
  width: 60px;
  --gx-spacing-large: 0;
  --gx-input-height-medium: 60px;
  &::part(base) {
    width: 100%;
    align-items: center;
    color: var(--gx-color-blue);
    border: none;
    background-color: var(--gx-color-tab-selected);
    box-shadow: none;
  }
  &:hover,
  &:focus-within {
    &::part(base) {
      background-color: var(--gx-color-tab-hover);
    }
  }
  &[disabled]:not([disabled='false']) {
    &::part(base) {
      opacity: 0.2;
    }
  }
}
