.text_with_icon {
  --gx-spacing-large: 5px;
  --gx-color-primary-50: var(--gx-color-blue);
  --gx-color-primary-60: var(--gx-color-button-hover);
  &::part(base) {
    box-shadow: none;
  }
  &::part(label) {
    margin: 0 5px;
    font-size: 14px;
    // line-height: 17px;
  }
  &[disabled='true'],
  &[disabled] {
    --gx-color-primary-50: var(--gx-color-button-disabled);
    &::part(base) {
      opacity: 1;
    }
  }
}

.text_with_icon--short {
  --gx-spacing-large: 0;
  --gx-color-primary-50: var(--gx-color-blue);
  --gx-color-primary-60: var(--gx-color-button-hover);
  &::part(base) {
    box-shadow: none;
  }
  &::part(label) {
    // margin: 0 5px;
    margin: 0;
    font-size: 14px;
    // line-height: 17px;
  }
  &[disabled='true'],
  &[disabled] {
    --gx-color-primary-50: var(--gx-color-button-disabled);
    &::part(base) {
      opacity: 1;
    }
  }
}
