@import '../../../styles/variables/index.scss';
.play {
  --gx-input-height-medium: 48px;
  --gx-input-border-radius-medium: 6px;
  --gx-color-gray-80: var(--gx-color-blue);
  --gx-color-gray-40: var(--gx-color-white);
  --gx-color-primary-40: var(--gx-color-white);
  --gx-color-primary-80: var(--gx-color-button-hover);
  --gx-color-primary-50: var(--gx-color-blue);
  --gx-color-primary-30: var(--gx-color-white);
  --gx-button-font-size-medium: 14px;
  --gx-input-line-height-medium: 17px;
  &::part(base) {
    width: 130px;
    height: 130px;
    @include flex(cc);
    box-shadow: none;
    background-color: var(--gx-color-blue);
    text-transform: uppercase;
    &:hover {
      background-color: var(--gx-color-button-hover);
    }
  }
  &::part(label) {
    margin: 0 3px;
  }
  &[disabled]:not([disabled='false']) {
    --gx-color-gray-80: var(--gx-color-button-disabled);
    &::part(base) {
      background-color: var(--gx-color-button-disabled);
      opacity: 1;
    }
  }
}
