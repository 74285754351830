@import '../../../styles/variables/index.scss';

.profile_dropdown {
  --gx-button-font-size-medium: 16px;
  --gx-input-line-height-medium: 20px;
  --gx-input-height-medium: auto;
  --gx-spacing-large: 0;
  --gx-label-mr:3px;
  &::part(base) {
    box-shadow: none;
  }
  &::part(label) {
    margin-right: var(--gx-label-mr);
    max-width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
