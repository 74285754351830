.switcher {
  --gx-spacing-large: 12px;
  --gx-input-height-medium: 40px;
  --gx-color-primary-95: var(--gx-color-white);
  --gx-color-gray-40: var(--gx-color-blue);
  --gx-color-primary-40: var(--gx-color-button-hover);
  &::part(base) {
    @include flex(cc);
    border-radius: 6px;
    border: none;
    box-shadow: none;
  }
  &::part(label) {
    @include flex(cc);
  }
  &[active]:not([active='false']) {
    --gx-color-primary-95: var(--gx-color-blue);
    --gx-color-gray-40: var(--gx-color-white);
    &::part(base) {
      background-color: var(--gx-color-blue);
    }
  }
  &[disabled]:not([disabled='false']) {
    &::part(base) {
      background-color: var(--gx-color-white);
      color: var(--gx-color-stroke);
      opacity: 1;
    }
  }
}
