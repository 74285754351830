@import "../../../styles/variables/index.scss";

.help {
  max-width: 1720px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include laptop-sm {
    grid-template-columns: 1fr;
  }
  @include tablet-lg {
    padding-bottom: 60px;
  }
}