@import '../../../styles/variables/index.scss';

.language_dropdown {
  --gx-button-font-size-medium: 16px;
  --gx-input-line-height-medium: 20px;
  --gx-input-height-medium: auto;
  --gx-spacing-large: 0;
  &::part(base) {
    box-shadow: none;
  }
  &::part(label) {
    margin-right: 3px;
  }
}
