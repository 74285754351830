@import "../../../styles/variables/index.scss";

.download_btn {
	@include mobile{
		&::part(base) {
			min-width: 205px;
		}
	}
	@media screen and (max-width:450px) {
		&::part(base) {
			min-width: 180px;
		}
	}
	@media screen and (max-width:400px) {
		width: 100%;
		&::part(base) {
			width: 100%;
		}
	}
	
	&__icon {
		@include square-icon(24)
	}
}