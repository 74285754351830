@import '../../styles/variables/index.scss';



input {
  width: 100% !important;
  opacity: 1 !important;
  color: var(--gx-color-primary) !important;
}

.select {
  position: relative;
  &_default {
    width: 100%;
    border: 1px solid var(--gx-input-border-color);
    box-sizing: border-box;
    border-radius: 6px;
    @include font(14);
    margin-top: 2px;
    &:hover {
      border-color: var(--gx-color-input-hover);
    }
    &:focus {
      border-color: var(--gx-color-blue);
    }
    &::placeholder {
      color: var(--gx-color-primary2);
    }
    &_label {
      @include font(12);
      &[required] {
        &::after {
          content: ' *';
          color: var(--gx-color-red);
        }
      }
    }
    &[error]:not([error='false']) {
      border-color: var(--gx-color-red);
      &::part(help-text) {
        display: block;
        color: var(--gx-color-red);
      }
    }
  }

  &_owner {
    width: 100%;
    border: 1px solid var(--gx-color-stroke);
    box-sizing: border-box;
    border-radius: 6px;
    @include font(16);
    &:hover {
      border-color: var(--gx-color-input-hover);
    }
    &:focus {
      border-color: var(--gx-color-blue);
    }
    &::placeholder {
      color: var(--gx-color-primary2);
    }
  }

  &:hover:not(:disabled) {
    background-color: var(--gx-input-background-color-hover);
    border-color: var(--gx-input-border-color-hover);
  }
}

.error-detail-text {
  font-size: 10px;
  color: var(--gx-color-red);
}

.button_icon {
  position: absolute;
  right: 10px;
  @include square-icon(16);
  color: var(--gx-color-blue);
}
