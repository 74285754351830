.danger_icon {
  &--small {
    --gx-input-height-medium: 48px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-red);
    --gx-color-gray-40: var(--gx-color-white);
    --gx-color-primary-40: var(--gx-color-white);
    --gx-color-primary-80: var(--gx-color-red);
    --gx-color-primary-50: var(--gx-color-red);
    --gx-color-primary-30: var(--gx-color-white);
    --gx-button-font-size-medium: 14px;
    --gx-input-line-height-medium: 17px;
    &::part(base) {
      width: 230px;
      @include flex(cc);
      box-shadow: none;
      background-color: var(--gx-color-red);
      &:hover {
        opacity: 0.7;
      }
    }
    &::part(label) {
      margin: 0 3px;
    }
    &[disabled]:not([disabled='false']) {
      --gx-color-gray-80: var(--gx-color-button-disabled);
      &::part(base) {
        background-color: var(--gx-color-button-disabled);
        opacity: 1;
      }
    }
  }
}
