@import '../../../styles/variables/index.scss';

.stroke_icon {
  &--s {
    --gx-input-height-medium: 48px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-blue);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-95: var(--gx-color-white);
    --gx-color-primary-80: var(--gx-color-button-hover);
    --gx-color-primary-40: var(--gx-color-button-hover);
    --gx-color-primary-30: var(--gx-color-blue);
    --gx-color-primary-50: var(--gx-color-blue);
    --gx-button-font-size-medium: 14px;
    --gx-input-line-height-medium: 17px;
    &::part(base) {
      width: 500px;
      @include flex(cc);
      box-shadow: none;
    }
    &::part(label) {
      margin: 0 3px;
    }
    &[disabled]:not([disabled='false']) {
      --gx-color-gray-80: var(--gx-color-button-disabled);
      --gx-color-gray-40: var(--gx-color-button-disabled);
      &::part(base) {
        opacity: 1;
      }
    }
  }
  &--small {
    --gx-input-height-medium: 48px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-blue);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-95: var(--gx-color-white);
    --gx-color-primary-80: var(--gx-color-button-hover);
    --gx-color-primary-40: var(--gx-color-button-hover);
    --gx-color-primary-30: var(--gx-color-blue);
    --gx-color-primary-50: var(--gx-color-blue);
    --gx-button-font-size-medium: 14px;
    --gx-input-line-height-medium: 17px;
    &::part(base) {
      min-width: 200px;
      padding: 0 10px;
      @include flex(cc);
      box-shadow: none;
    }
    &::part(label) {
      margin: 0 3px;
    }
    &[disabled]:not([disabled='false']) {
      --gx-color-gray-80: var(--gx-color-button-disabled);
      --gx-color-gray-40: var(--gx-color-button-disabled);
      &::part(base) {
        opacity: 1;
      }
    }
  }
}
