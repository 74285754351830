@import "../../../styles/variables/index.scss";

.button {
  &__icon {
    @include square-icon(24);
  }
  &__add{
    @include tablet-md {
      width: 100%;
      &::part(base) {
        width: 100%;
      }
    }
  }
}