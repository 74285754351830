.menu_default {
  --gx-color-primary-95: var(--gx-color-list-hover);
}

.header_profile {
  --gx-color-primary-95: var(--gx-color-list-hover);
  min-width: 180px;
}

.menu_dropdown {
  --gx-color-primary-95: var(--gx-color-list-hover);
  max-width: 380px;
}

.menu_dropdown {
  &--short {
    --gx-color-primary-95: var(--gx-color-list-hover);
    width: 250px;
  }
  &--long {
    --gx-color-primary-95: var(--gx-color-list-hover);
    width: 380px;
  }
}
