@import '../../../styles/variables/index.scss';

.wrapper {
  margin-bottom: 11px;
  display: flex;
  gap: 20px;
  @include laptop-sm {
    flex-direction: column;
    margin-bottom: 20px;
  }
  @include laptop-lg {
    margin-bottom: 10px;
  }
  @include mobile-lg {
    justify-content: space-between;
  }
}

.buttons_wrapper {
  display: flex;
  > div:not(:first-child) {
    margin-left: 20px;
  }
  @include laptop-xs {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  @include tablet-md {
    margin-top: 160px;
    margin-bottom: 0px;
  }
}

.inputs_wrapper {
  display: flex;
  width: 100%;
  > div {
    min-height: 48px;
  }
  > div:not(:first-child) {
    margin-left: 20px;
  }
  @include tablet-md {
    flex-wrap: wrap-reverse;
    > div:not(:first-child) {
      margin-left: 0;
      margin-bottom: 20px;
    }

    background-color: white;
    position: fixed;
    width: 100vw;
    z-index: 4;
    padding: 20px;
    margin-left: -20px;
    top: 59px;
    border-bottom: 1px solid var(--gx-color-stroke);
    border-top: 1px solid var(--gx-color-stroke);
  }
  @include mobile{
    padding: 20px 10px;
    margin-left: -10px;
  }
}

.search_wrapper {
  width: 100%;
}

.button {
  &_wrapper {
    @include tablet-md {
      width: 100%;
      gx-button{
        width: 100%;
        &::part(base) {
          width: 100%;
          min-width: auto;
        }
      }
    }
  }
  &_fixed {
    @include tablet-md {
      position: fixed;
      bottom: 106px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      width: 100%;
      padding: 0 10px;
      margin-left: 0 !important;
    }
  }
  &__add {
    @include tablet-md {
      &::part(base) {
        width: 97vw;
      }
    }
  }
}
.wrap {
  display: flex;
}
