@import "../../../styles/variables/index.scss";

.profile {
  --header-height:63px;
  display: flex;
  height: calc(100vh - var(--header-height)); //высота шапки,TODO: пофиксить в др косп

  @include tablet-lg{
    --header-height:60px
  }

  &__left {
    padding: 60px 30px 30px;
    width: 100%;
    overflow-y: auto;
    max-width: 740px;
    border-right: 1px solid var(--gx-color-line-light-gray);
    @include scrollBar;

    @include laptop-xs {
      padding: 30px;
    }

    @include tablet-lg {
      max-width: none;
      border: none;
      padding-bottom: 110px;

      &[data-showprofile="true"] {
        display: none;
      }
    }

    @include mobile {
      padding: 20px 15px 110px 15px;
    }

    @include xsReg {
      padding-bottom: 90px;
    }

    &_wrap {
      display: flex;
      gap: 20px;
      flex-direction: column;
      max-width: 600px;

      @include laptop-xs {
        max-width: none;
      }
    }
  }

  &__right {
    padding: 60px 30px;
    @include scrollBar;

  }

  &__layout {
    padding: 30px;

    @include tablet-lg {
      padding: 20px 20px 80px 20px;
    }
  }

  &_form {
    width: 100%;

    &::part(base) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    @include tablet-lg {
      &::part(base) {
        padding-bottom: 60px;
      }

      &[data-showprofile="false"] {
        display: none;
      }
    }

    &__wrap {
      @include flex(lc);
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 0;
    }

    gx-switch {
      --gx-input-font-size-medium: 14px;
    }

    &__back {
      display: none;
      width: 24px;
      height: 24px;
      margin-right: 5px;

      &::part(base) {
        @include flex(cc);
      }

      gx-icon {
        --icon-width: 24px;
        --icon-height: 24px;
      }

      @include tablet-lg {
        display: block;
      }
    }

    &__block {
      padding: 60px 60px 30px;
      flex-grow: 1;
      @include scrollBar;

      @include laptop-xs {
        padding: 30px;
        overflow-y: auto;
      }

      @media screen and (max-width:450px) {
        padding: 20px 15px 90px;
      }
    }

    &__security {
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__subtitle {
      margin-top: 0;
      margin-bottom: 15px;
      @include font(16);
      font-weight: 600;
    }

    &__grid {
      display: grid;
      max-width: 600px;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      margin-bottom: 40px;
    }

    &__input{
      gx-input, gx-phone-input {
        --gx-spacing-xxx-small: 7px;
      }
    }

    &__buttons {
      min-height: 108px;
      @include flex(rc);
      border-top: 1px solid var(--gx-color-stroke);
      background: var(--gx-color-white);

      @media screen and (max-width:450px) {
        min-height: 0;
        border: none;
      }

      &_save {
        margin-right: 30px;

        @include tablet-lg {
          margin-bottom: 20px;
        }

        @media screen and (max-width:450px) {
          margin: 0;
          position: fixed;
          left: 0;
          bottom: 80px;
          width: 100%;
          padding: 0 15px;
          &::part(base) {
            width: 100%;
          }
        }
      }
    }
  }
}

.current_tariff {
  height: max-content;
  display: flex;
  flex-direction: column;
  --progress-size: 120px;

  padding: 50px 30px;
  border-radius: 6px;
  max-width: 600px;
  width: 100%;
  background: var(--gx-color-white);
  box-shadow: var(--gx-dropdown-shadow);

  @include laptop-xs {
    padding: 30px;
    max-width: none;
    width: 100%;
  }

  @include xs {
    min-width: 200px;
    min-height: 0;
  }
  @include mobile {
    --progress-size: 110px;
  }
  @include mobile-lg {
    --progress-size: 100px;
  }

  &--profile {
    height: 100%;

    @include laptop-sm {
      height: auto
    }

    @include tablet-lg {
      flex-direction: row;
      height: auto;
      align-items: flex-start;
      margin-bottom: 30px
    }

    @media screen and (max-width:680px) {
      flex-direction: column;
    }
  }

  &__block {
    @include flex(st);
    gap: 40px;
    width: 100%;

    @include laptop-xs {
      @include flex(sc);
      gap: 20px;
    }
    @include mobile-lg{
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &--profile {
      flex-direction: column-reverse;
      @include mobile-lg{
        flex-direction: row;
      }
    }
  }

  &__info {
    width: 100%;
    margin-top: 40px;

    @include laptop-xs {
      margin-top: 20px;
    }

    &_text {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-weight: 400;
      @include font(16);
      color: var(--gx-color-primary);
    }
  }

  &__link-btn {
    margin-top: 20px;
    align-self: flex-start;

    @include laptop-xs {
      align-self: center;
      margin-bottom: 20px;
    }
  }

  &__cancel {
    margin-top: 40px;
    align-self: flex-start;
    font-weight: 600;
    @include font(14);

    @include laptop-xs {
      align-self: center;
    }

    &-btn {
      &::part(label) {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.advantages {
  &__list {
    @include flex('lc');
    list-style: none;
    padding-left: 0;
    margin-bottom:0;
  }

  &__item {
    flex-grow: 1;
    @include flex('lc');
  }
  &__item + &__item {
    margin-left:10px;
  }
  &__text{
    margin-left: 10px;
    @include font(13);
    font-weight: 600;
  }

  &__icon {
    @include square-icon(24);
    color:var(--gx-color-blue)
  }
}