@import '../../../styles/variables/index.scss'; 

.user_company{
  padding: 30px;
  background-color: var(--gx-color-white);
  box-shadow: var(--gx-dropdown-shadow);
  border-radius: 6px;
  @include mobile {
    padding: 20px;
  }
  &__top{
    @include flex('sc');
    margin-bottom: 15px;
  }
  &__img{
    margin: 0;
    display: flex;
    align-items: center;
    @include mobile{
      @include font(14);
    }
    gx-icon {
      color: var(--gx-color-blue);
      width: 24px;
      height: 24px;
      margin-right: 13px;
      @include mobile {
        margin-right: 5px;
      }
    }
  }
  &__status{
    margin: 0;
    color: var(--gx-color-red);
    opacity: .7;
    @include mobile {
      @include font(14)
    }
  }
  &__bottom{
    @include flex('lc');
    img{
      width: 50px;
      border-radius: 100%;
    }
  }
  &__info{
    margin-left: 20px;
    @include mobile {
      margin-left: 10px;
    }
    &_title{
      @include font(22);
      font-weight: 600;
    }
    &_role{
      margin: 0;
      margin-top: 5px;
      opacity: .7;
    }
  }
}