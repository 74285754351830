@import "../../../styles/variables/index.scss";

.tariffs {
  display: flex;
  flex-direction: column;
  max-width: 1720px;  
  padding-bottom: 150px;
  position: relative;


  @include tablet-lg {
    padding-bottom: 90px;
  }

  &_left-block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__wrapper {
    @include flex(ss);
    flex-direction: row;
    gap: 20px;
    width: 100%;

    @include lg {
      flex-direction: column-reverse;
    }

    @include mobile {
      align-items: center;
    }
  }

  &__promotion {
    position: absolute;
    top: -10px;
    left: -12px;


    &_title {
      @include flex(cc);
      width: 98px;
      height: 20px;
      background: var(--gx-color-tab-selected);
      border-radius: 5px;
    }

    &_date {
      margin-top: 7px;
      @include flex(cc);
      padding: 2px 11px;
      background: var(--gx-color-tab-selected);
      border-radius: 5px;
    }

    &_icon {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    &_label {
      font-weight: 600;
      @include font(12);
      color: var(--gx-color-blue);

      &-date {
        margin: 0;
        padding: 0;
        font-weight: 600;
        @include font(12);
        color: var(--gx-color-button-hover);
      }
    }
  }

  &__marketing-promotion {
    margin: 10px 0;

    &_label {
      margin: 0;
      font-weight: 600;
      @include font(20);
      color: var(--gx-color-blue);

      &-active {
        color: var(--gx-color-yellow);
      }
    }
  }

  &__price-promotion {
    margin-bottom: 2px;

    &_label {
      padding: 0;
      margin: 0;
      font-weight: 600;
      @include font(35);
      color: var(--gx-color-blue);
      text-align: center;

      &-active {
        color: var(--gx-color-yellow);
      }
    }
  }

  &__title {
    margin-bottom: 4px;
    font-weight: 600;
    @include font(16);
    color: var(--gx-color-blue);

    &-active {
      color: #ffffff;
    }
  }

  &__buttons {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: calc(100vw - 70px);
    height: 108px;
    padding-right: 30px;
    @include flex(rc);
    border-top: 1px solid var(--gx-color-stroke);
    background: var(--gx-color-white);
    z-index: 3;


    @include tablet-lg {
      position: static;
      border: none;
      width: auto;
      height: auto;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  }

  &__press_tariff {
    flex-direction: row;
    @include flex(sc);
    padding: 5px 30px;
    gap: 34px;
    margin-right: 40px;
    border: 1px solid var(--gx-color-stroke);
    border-radius: 10px;

    font-weight: 600;

    @include lg {
      display: none;
    }


    &__value {
      margin-left: 10px;
      color: var(--gx-color-button-hover);
      cursor: pointer;
    }

    &__value-price {
      margin-left: 10px;
      @include font(22);
      color: var(--gx-color-button-hover);
    }
  }

  &__button {
    &::part(base) {
      min-width: 210px;
    }

    &_center {
      @include flex(cc);
      margin-top: 20px;
    }
  }

  &__current_tariff {
    max-width: 440px;
    width: 100%;
    height: 100%;

    @include lg {
      max-width: none;
      min-width: 220px;
    }
  }

  &_block {
    display: grid;
    --columns:4;
    grid-template-columns: repeat(var(--columns), minmax(250px, 300px));
    grid-gap: 20px;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: 1620px) {
      --columns:3;
    }

    @include laptop-sm {
      --columns:2;
    }
    @include lg {
      --columns:3;
    }
    @include md {
      --columns:2;
    }

    @media screen and (max-width:650px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mobile {
      margin-right: 0;
    }

    &_desc {
      width: 100%;

      &_ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &_li {
        display: flex;
        text-align-last: left;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &_icon {
        @include square-icon(16);
        color: var(--gx-color-blue);

        &[active]:not([active="false"]) {
          color: currentColor;
        }

        &_wrap {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }
    }
  }

  &_desc {
    margin-top: 40px;
    padding: 0 10px;

    &__title {
      margin: 0;
      margin-bottom: 10px;
      @include font(20);
      color: var(--gx-color-primary);
    }

    @include mobile {
      margin-top: 20px;
    }

    &__text {
      max-width: 1260px;
      width: 100%;
      margin: 0;
      @include font(14);
      color: var(--gx-color-primary);


      &>p {
        max-width: 1260px;
        width: 100%;
        margin: 0;
        @include font(14);
        color: var(--gx-color-primary);

      }
    }
  }

  &_desc-promotion {
    margin-top: 20px;
    padding: 0 10px;

    &__icon {
      position: relative;
      top: 5px;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 10px;
      @include font(20);
      color: var(--gx-color-blue);
    }

    &__finished {
      margin-top: 10px;
    }

    &__dateTo {
      margin-top: 12px;

      &_title {
        font-weight: 400;
        @include font(16);
        color: var(--gx-color-primary);
      }

      &_icon {
        position: relative;
        top: 6px;
        width: 24px;
        height: 24px;
        color: var(--gx-color-blue);
        margin-right: 6px;
      }

      &_date {
        margin-left: 10px;
        font-weight: 600;
        @include font(16);
        color: var(--gx-color-primary);
      }
    }
  }

  &__price {
    margin: 0;
    padding: 0;

    &_block {
      display: flex;
      justify-content: center;
    }

    &-line {
      padding: 0 5px;
      margin: 0;
      position: relative;
      font-weight: 600;
      @include font(29);
      white-space: nowrap;
      color: var(--gx-color-line-price);

      &:before {
        @extend %line-through;
      }

      &-active {
        color: #ffffff;

        &:before {
          @extend %line-through;
          border-color: var(--gx-color-yellow) !important;
        }
      }
    }
  }

  &__marketing {
    margin: 0;
    padding: 0;
    font-weight: 400;
    @include font(14);
  }
}

.icons {
  position: relative;
  bottom: -4px;
  width: 24px;
  height: 19px;
  margin-right: 10px;
  color: var(--gx-color-blue);
}

.tariffs_button{
  &::part(base){
    min-width: 210px;
  }
}