.text {
  &--black {
    --gx-spacing-large: 0;
    --gx-color-primary-50: var(--gx-color-primary);
    --gx-color-primary-60: var(--gx-color-primary2);
    --gx-input-height-medium: auto;
    &::part(base) {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      box-shadow: none;
    }
  }
}
