@import "../../../styles/variables/index.scss";

.input_file {
	&__label {
		position: absolute;
		top:0;
		right:0;
		bottom: 0;
		left: 0;
		cursor: pointer;
	}
	&__input{
		visibility: hidden;
		width: 0;
	}
	&__icon {
		@include square-icon(24)
	}
	@include mobile {
		&::part(base){
			min-width: 205px;
		}
	}
	@media screen and (max-width:450px) {
		&::part(base) {
			min-width: 180px;
		}
	}
	@media screen and (max-width:400px) {
		width: 100%;
		&::part(base) {
			width: 100%;
		}
	}
}

