.modal_close {
  // position: absolute;
  // top: 37px;
  // right: 40px;
  margin-left: auto;
  margin-top: 37px;
  margin-right: 40px;
  --gx-input-line-height-small: 1;
  --gx-color-primary-95: transparent;
  &::part(base) {
    background-color: transparent;
    height: max-content;
    padding: 0;
    border: none;
    color: var(--gx-color-primary2);
    box-shadow: none;
    width: max-content;
    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--gx-color-blue);
    }
  }
  &::part(label) {
    line-height: 100%;
  }
}

.modal_close--landing {
  &::part(base) {
    padding-top: 2px;
    color: white;
    --gx-color-primary-40: var(--gx-color-blue);
    --gx-color-primary-50: var(--gx-color-primary2);
    --gx-color-primary-60: var(--gx-color-blue);
    background-color: var(--gx-color-blue-background);
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    &:hover {
      background-color: var(--gx-color-blue);
    }
  }
  &::part(label) {
    line-height: 100%;
  }
}
