@import "../../../../styles/variables/index.scss";

.promocode {
    padding:30px;
    @include tablet-lg {
        padding:20px 20px 80px 20px;
    }

    &__header {
        @include flex(sc);
        flex-direction: row;
        width: 100%;

        @include ipad {
            flex-direction: column;
        }
    }

    &__title {
        @include mobile {
            padding-left: 10px;
        }
    }

    &__action {
        @include flex(sc);
        flex-direction: row;
        margin-top: 0px;
        margin-bottom: 40px;

        @include xl {
            align-items: stretch;
            flex-direction: column-reverse;
            gap: 20px;
            margin-top: 15px;
        }

        @include mobile {
            margin-bottom: 20px;
        }

        &_buttons {
            display: flex;
            align-items: center;
            gap: 60px;
            max-width: 500px;
            width: 100%;

            @include xl {
                gap: 0;
                justify-content: space-between;
                max-width: 100%;
            }

            @include tablet-md {
                flex-direction: column;
                gap: 20px;
            }

        }

        &_button {
            position: relative;

            &::part(base) {
                padding-left: 50px;
            }

            &-promocode {
                &::part(base) {
                    min-width: 210px;
                }
                @include tablet-md { 
                    width: 100%;
                    &::part(base) {
                        width: 100%;
                    }
                
                }
            }
            &-fixed{
                @media screen and (max-width:450px) {
                    position: fixed;
                    bottom: 79px;
                    padding: 0 10px;
                    z-index: 10;
                }
            }
        }

        &_income {
            @include flex(cc);
            gap: 20px;

            @include laptop-xs {    
                justify-content: space-between;
            }
            @include tablet-md {
                flex-direction: column;
                align-items: center;
            }
        }

        &_price {
            &-wrapper {
                @include flex(lc);
                flex-wrap: wrap;
                gap: 10px;
                width: 100%;

                @include sm {
                    flex-direction: column;
                    align-items: stretch;
                }
            }

            &-block {
                @include flex(lc);
                padding: 5px 30px;
                border: 1px solid var(--gx-color-stroke);
                border-radius: 10px;

                @include tablet-md{
                    justify-content: center;
                }

                @include mobile {
                    padding: 5px 10px;
                }
            }

            &-text {
                font-weight: 600;
                @include font(16);
                color: var(--gx-color-primary);

                @include mobile {
                    @include font(14);
                }
            }

            &-subtext {
                margin-left: 5px;
                font-weight: 600;
                @include font(22);
                color: var(--gx-color-button-hover);

                @include mobile {
                    @include font(18);
                }
            }

            &-icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                color: var(--gx-color-blue);
            }
        }
    }

    &__icon-btn {
        width: 24px;
        height: 15px;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 20px;
    --track-color: transparent;
    --indicator-color: var(--gx-color-white);

    &::part(base) {
        width: 20px;
        height: 20px;
    }
}