.transparent_with_icon {
  --gx-input-line-height-small: 1;
  --gx-color-primary-95: transparent;
  &::part(base) {
    background-color: transparent;
    height: max-content;
    padding: 0;
    border: none;
    color: var(--gx-color-primary2);
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--gx-color-blue);
    }
  }
  &::part(label) {
    line-height: 100%;
  }
  &--blue {
    --gx-input-line-height-small: 1;
    --gx-color-primary-95: transparent;
    &::part(base) {
      background-color: transparent;
      height: max-content;
      padding: 0;
      border: none;
      color: var(--gx-color-blue);
      font-size: 14px;
      box-shadow: none;
      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--gx-color-button-hover);
      }
    }
    &::part(label) {
      line-height: 100%;
    }
  }
  &--red {
    --gx-input-line-height-small: 1;
    --gx-color-primary-95: transparent;
    &::part(base) {
      background-color: transparent;
      height: max-content;
      padding: 0;
      border: none;
      color: var(--gx-color-red);
      font-size: 14px;
      box-shadow: none;
      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--gx-color-red-hover);
      }
    }
    &::part(label) {
      line-height: 100%;
    }
  }
  &--blue-weight {
    &::part(base) {
      background-color: transparent;
      height: max-content;
      padding: 0;
      border: none;
      color: var(--gx-color-blue);
      font-size: 12px;
      font-weight: 700;
      box-shadow: none;
      &:hover {
        background-color: transparent;
        color: var(--gx-color-button-hover);
      }
    }
    &::part(label) {
      line-height: 100%;
    }
  }
  &--gray {
    &::part(base) {
      background-color: transparent;
      height: max-content;
      padding: 0;
      border: none;
      color: var(--gx-color-button-disabled);
      font-size: 12px;
      font-weight: 700;
      box-shadow: none;
      &:hover {
        background-color: transparent;
        color: var(--gx-color-button-hover);
      }
    }
    &::part(label) {
      line-height: 100%;
    }
  }
}
