.report_download {
  --gx-input-height-medium: 100%;
  --loading-progress: 100%;
  --disabled-color: var(--gx-color-button-disabled);
  --gradient: linear-gradient(
    to right,
    var(--gx-color-blue) 0 var(--loading-progress),
    var(--gx-color-input-hover) var(--loading-progress) 100%
  );
  width: 100%;
  height: 100%;
  background: var(--gradient);
  &::part(base) {
    width: 100%;
    @include flex(cc);
    box-shadow: none;
    border: none;
    background: initial;
    color: var(--gx-color-white);
    &:hover {
      background: var(--gx-color-button-hover);
    }
  }
  &[disabled]:not([disabled='false']) {
    &::part(base) {
      background: var(--disabled-color);
      opacity: 1;
    }
  }
  &--ref {
    --gx-color-primary-95: transparent;
    &::part(base) {
      display: flex;
      align-items: center;
      background-color: transparent;
      padding: 0;
      border: none;
      color: var(--gx-color-blue);
      font-weight: 400;
      box-shadow: none;
      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--gx-color-button-hover);
      }
    }
    &::part(label) {
      line-height: 100%;
    }
    &::part(icon-right) {
      margin-left: 5px;
    }
    &[disabled]:not([disabled='false']) {
      --gx-color-gray-80: var(--gx-color-button-disabled);
      &::part(base) {
        opacity: 1;
      }

      &::part(label) {
        color: var(--gx-color-gray);
      }
    }
  }
  &--all {
    --gx-input-height-medium: 70px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-blue);
    --gx-color-gray-40: var(--gx-color-blue-dark);
    --gx-color-primary-40: var(--gx-color-black);
    --gx-color-primary-80: var(--gx-color-button-hover);
    --gx-color-primary-50: var(--gx-color-blue);
    --gx-color-primary-30: var(--gx-color-black);
    --gx-button-font-size-medium: 20px;
    --gx-input-line-height-medium: 24px;
    @include mobile {
      --gx-input-height-medium: 48px;
    }
    &::part(base) {
      width: 300px;
      @include flex(cc);
      box-shadow: none;
      background-color: var(--gx-color-yellow);
      box-shadow: 0px 4px 20px rgba(103, 74, 5, 0.32);
      border: none;
      @include mobile {
        width: 100%;
      }
      &:hover,
      &:active {
        background-color: var(--gx-color-yellow-hover);
      }
    }
    &::part(label) {
      margin: 0 3px;
    }
    &[disabled]:not([disabled='false']) {
      --gx-color-gray-80: var(--gx-color-button-disabled);
      &::part(base) {
        box-shadow: 0px 4px 20px rgba(112, 124, 138, 0.32);
        color: var(--gx-color-primary2);
        background-color: var(--gx-color-button-disabled);
        opacity: 1;
      }
    }
  }
  &--all-additional {
    --gx-input-height-medium: 60px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-blue);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-40: var(--gx-color-black);
    --gx-color-primary-80: var(--gx-color-button-hover);
    --gx-color-primary-50: var(--gx-color-blue);
    --gx-color-primary-30: var(--gx-color-black);
    --gx-button-font-size-medium: 16px;
    --gx-input-line-height-medium: 19px;
    &::part(base) {
      width: 300px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-shadow: none;
      background-color: transparent;
      box-shadow: 0px 0px 20px rgba(90, 77, 38, 0.2);
      border: none;
      &:hover,
      &:active {
        color: var(--gx-color-button-hover);
      }
    }
    &::part(icon-right) {
      margin-left: auto;
    }
    &::part(label) {
      margin: 0 3px;
    }
    &[disabled]:not([disabled='false']) {
      //--gx-color-gray-80: var(--gx-color-button-disabled);
      &::part(base) {
        //color: var(--gx-color-primary2);
        //background-color: var(--gx-color-button-disabled);
        opacity: 1;
      }
    }
  }
}
