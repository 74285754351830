.panel_status {
  --gx-input-height-medium: 30px;
  --gx-input-border-radius-medium: 6px;
  --gx-color-gray-80: var(--gx-color-tab-selected);
  --gx-color-gray-40: var(--gx-color-blue);
  --gx-color-primary-40: var(--gx-color-blue);
  --gx-color-primary-80: var(--gx-color-tab-hover);
  --gx-color-primary-50: var(--gx-color-tab-hover);
  --gx-color-primary-30: var(--gx-color-blue);
  --gx-button-font-size-medium: 12px;
  --gx-input-line-height-medium: 15px;
  width: 100%;

  &::part(base) {
    width: 100%;
    @include flex(cc);
    box-shadow: none;
    background-color: var(--gx-color-tab-selected);
    &:hover {
      background-color: var(--gx-color-tab-hover);
    }
  }
  &::part(label) {
    margin: 0 3px;
  }
  //   &[disabled],
  //   &[disabled="true"] {
  //     --gx-color-gray-80: var(--gx-color-button-disabled);
  //     &::part(base) {
  //       background-color: var(--gx-color-button-disabled);
  //       opacity: 1;
  //     }
  //   }
  &--left {
    --gx-input-height-medium: 30px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-tab-selected);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-40: var(--gx-color-blue);
    --gx-color-primary-80: var(--gx-color-tab-hover);
    --gx-color-primary-50: var(--gx-color-tab-hover);
    --gx-color-primary-30: var(--gx-color-blue);
    --gx-button-font-size-medium: 12px;
    --gx-input-line-height-medium: 15px;
    width: 100%;

    &::part(base) {
      width: 100%;
      @include flex(cc);
      box-shadow: none;
      background-color: var(--gx-color-tab-selected);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid var(--gx-color-tab-hover);
      &:hover {
        background-color: var(--gx-color-tab-hover);
      }
    }
    &::part(label) {
      margin: 0 3px;
    }
  }
  &--right {
    --gx-input-height-medium: 30px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-tab-selected);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-40: var(--gx-color-blue);
    --gx-color-primary-80: var(--gx-color-tab-hover);
    --gx-color-primary-50: var(--gx-color-tab-hover);
    --gx-color-primary-30: var(--gx-color-blue);
    --gx-button-font-size-medium: 12px;
    --gx-input-line-height-medium: 15px;
    width: 100%;

    &::part(base) {
      width: 100%;
      @include flex(cc);
      box-shadow: none;
      background-color: var(--gx-color-tab-selected);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        background-color: var(--gx-color-tab-hover);
      }
    }
    &::part(label) {
      margin: 0 3px;
    }
  }
  &--step {
    --gx-input-height-medium: 30px;
    --gx-input-border-radius-medium: 6px;
    --gx-color-gray-80: var(--gx-color-tab-selected);
    --gx-color-gray-40: var(--gx-color-blue);
    --gx-color-primary-40: var(--gx-color-blue);
    --gx-color-primary-80: var(--gx-color-tab-hover);
    --gx-color-primary-50: var(--gx-color-tab-hover);
    --gx-color-primary-30: var(--gx-color-blue);
    --gx-button-font-size-medium: 12px;
    --gx-input-line-height-medium: 15px;
    --gx-spacing-large: 0;
    &::part(base) {
      width: 40px;
      @include flex(cc);
      box-shadow: none;
      background-color: var(--gx-color-tab-selected);
      &:hover {
        background-color: var(--gx-color-tab-hover);
      }
    }
    &::part(label) {
      margin: 0 3px;
    }
  }
}
