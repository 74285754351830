.tab {
  &::after {
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
    will-change: transform, opacity;
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 2px 2px 0px 0px;
    background-color: var(--gx-color-blue);
    opacity: 0;
    transform: scale(0, 1);
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      transform: scale(1, 1);
      background-color: var(--gx-color-button-hover);
    }
  }
  &[active='true'] {
    &::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  
  @include tablet-md {
    width: 100%;
  }

  &::part(base) {
    background-color: transparent;
    height: 61px;
    width: 150px;
    padding: 0;
    border: none;
    color: var(--gx-color-primary1);
    font-weight: 600;
    box-shadow: none;
    box-sizing: content-box;
    &:hover,
    &:focus {
      border-bottom: none;
    }
    @include tablet-md {
      font-size: 15px;
      width: 100%;
    }
  }
  &::part(label) {
    line-height: 350%;
    @include tablet-md {
      line-height: 500%;
    }
  }
}
