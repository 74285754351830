@import '../../../styles/variables/index.scss';

.nav {
  position: fixed;
  height: 100%;
  padding: 95px 6px 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 85px;
  z-index: 4;
  background-color: #fff;
  border-right: 1px solid var(--gx-color-stroke);

  @include xsReg {
    height: auto;
    padding: 5px 20px;
    max-width: 100%;
    border: none;
    padding-bottom: calc(env(safe-area-inset-bottom, 20px) + 10px);
  }
  li {
    list-style: none;
  }
  &_out{
    margin-right: 3px ;
    display: none;
    @include xsReg{
      display: block;
    }
  }
  &_list {
    margin: 0;
    padding: 0;
    @include xsReg {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  &_item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    @include xsReg{
      margin-bottom: 0 !important;
      width: 100%;
      max-width: 85px;
    }
  }

  &_icon {
    height: 24px;
    width: 24px;
  }

  &_link {
    height: 59px;
    border-radius: 6px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--gx-color-primary2);
    position: relative;
    &:hover,
    &:focus {
      background-color: var(--gx-color-list-hover);
      color: var(--gx-color-primary2);
    }
  }

  &_text {
    margin-top: 4px;
    display: block;
    font-weight: 500;
    @include font(11);
    @include mobile-lg {
      font-size: 9px;
    }
  }

  &_logout {
    position: absolute;
    bottom: 50px;
    left: 50%;
    cursor: pointer;
    transform: translateX(-50%);

    @include tablet-lg {
      bottom: 100px;
    }
    
    @include xsReg {
      display: none;
    }
    &-button {
      height: 54px;
      cursor: pointer;
      width: 54px;
      background-color: transparent;
      border: none;
      color: var(--gx-color-red);
      border-radius: 6px;
      &:hover {
        color: var(--gx-color-blue);
      }
      &:focus {
        color: var(--gx-color-blue);
      }
    }
  }
}
.selected {
  background-color: var(--gx-color-tab-selected);
  color: var(--gx-color-blue);
  height: 59px;
  border-radius: 6px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover,
  &:focus {
    background-color: var(--gx-color-tab-selected);
    color: var(--gx-color-blue);
  }
}
