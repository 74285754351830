@import '../../../styles/variables/index.scss';

.fill_icon {
  &--full {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 6px;
    color: var(--gx-color-white);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    box-shadow: none;
    background-color: var(--gx-color-blue);
    &:hover {
      background-color: var(--gx-color-button-hover);
      color: var(--gx-color-white);
    }
  }
  &--landing {
    border-radius: 6px;
    color: var(--gx-color-white);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
    @include flex(cc);
    box-shadow: none;
    background-color: var(--gx-color-blue);
    max-width: 486px;
    height: 56px;
    padding: 0 20px;
    line-height: 55px;
    text-align: center;
    &:hover {
      background-color: var(--gx-color-button-hover);
      color: var(--gx-color-white);
    }
  }
  &--panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: var(--gx-color-white);
    @include font(14);
    font-weight: 600;
    width: 100%;
    box-shadow: none;
    background-color: var(--gx-color-blue);
    &:hover {
      background-color: var(--gx-color-button-hover);
      color: var(--gx-color-white);
    }
    @include mobile {
      &[data-cy="current-report-link"]{
        border-radius:6px;
      }
    }
  }
  &--white {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid var(--gx-color-blue);
    border-radius: 6px;
    background-color: var(--gx-color-white);
    font-weight: 600;
    color: var(--gx-color-blue);
    @include font(14);
    box-shadow: none;
  }
  &--orange {
    height: 56px;
    border-radius: 6px;
    color: var(--gx-color-black);
    font-weight: 600;
    @include font(14);
    text-transform: uppercase;
    width: 235px;
    border: none;
    @include flex(cc);
    padding: 0 20px;
    box-shadow: var(--gx-orange-shadow);
    background-color: var(--gx-color-yellow);
    &:hover {
      background-color: var(--gx-color-yellow-hover);
      color: var(--gx-color-black);
    }
  }
  &--small {
    @include flex(cc);
    margin: 5px;
    max-width: 230px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    background-color: var(--gx-color-yellow);
    color: var(--gx-color-blue-dark);
    font-weight: bold;
    @include font(12);
    box-shadow: none;
    &:hover {
      background-color: var(--gx-color-yellow-hover);
      color: var(--gx-color-black);
    }
  }
}

.disabled {
  background-color: var(--gx-color-button-disabled);
  opacity: 1;
  pointer-events: none;
}
