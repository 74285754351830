.assidepanel_tab {
  --icon-width: 24px;
  --icon-height: 24px;
  &::after {
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
    will-change: transform, opacity;
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 2px 2px 0px 0px;
    background-color: var(--gx-color-blue);
    opacity: 0;
    transform: scale(0, 1);
  }
  &:hover,
  &:focus {
    --gx-color-primary-95: transparent;
    &::after {
      opacity: 1;
      transform: scale(1, 1);
      background-color: var(--gx-color-button-hover);
    }
  }
  &[active='true'] {
    &::after {
      opacity: 1;
      transform: scale(1, 1);
      background-color: var(--gx-color-blue);
    }
    &::part(base) {
      color: var(--gx-color-blue);
    }
  }

  &::part(base) {
    border: none;
    color: var(--gx-color-primary1);
    font-weight: 600;
    box-shadow: none;
    box-sizing: content-box;
    border: none;
    height: 100%;
    width: 87px;
    padding: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 6px 6px 0 0;
    @include md {
      font-size: 15px;
    }
  }
  &::part(label) {
    line-height: 350%;
    @include md {
      line-height: 500%;
    }
  }
}
