@import '../../../styles/variables/index.scss';

.empty {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80%;
    &__faq{
        height: 40vh;
    }
    @include laptop-sm {
        margin: 100px 0;
    }
    @include mobile{
        margin: 70px 0;
    }
    &__img{
        max-width: 200px;
        max-height: 200px;
        margin-bottom: 20px;
    }
}