.color_select {
  &::part(base) {
    padding: 3px;
    border-radius: 50%;
    border: 1px solid transparent;
    height: max-content;
    box-shadow: none;
    &:hover {
      border: 1px solid var(--gx-color-blue);
    }
  }
  &::part(label) {
    @include flex(cc);
  }
  &::part(icon-left) {
    display: none;
  }
  &[checked]:not([checked='false']) {
    &::part(icon-left) {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.color_trigger {
  --gx-button-font-size-medium: 14px;
  --gx-input-line-height-medium: 17px;
  --gx-font-weight-semibold: normal;
  --gx-color-primary-95: var(--gx-color-white);
  --gx-color-gray-40: var(--gx-color-blue);
  --gx-color-primary-40: var(--gx-color-button-hover);
  &::part(base) {
    height: max-content;
    border: none;
    padding: 0;
    align-items: center;
    box-shadow: none;
    background-color: transparent;
  }
  &::part(icon-left) {
    margin-right: 8px;
    border: 1px solid var(--gx-color-line-light-gray);
    border-radius: 100%;
  }
}
