.tariffs_item {
  width: 100%;
  height: 100%;
  --gx-color-primary-95: var(--gx-color-white);
  --gx-color-primary-70: var(--gx-color-blue);
  --gx-color-primary-80: var(--gx-color-blue);
  &::part(base) {
    min-width: 150px;
    min-height: 113px;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    color: var(--gx-color-primary);
    box-shadow: none;
    @include tablet-lg {
      width: 100%;
      min-width: 100px;
      min-height: 113px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &::part(icon-left) {
    justify-content: center;
    margin-bottom: 15px;
    color: var(--gx-color-blue);
    @include font(16);
    font-weight: 600;
    white-space: normal;
  }
  &::part(label) {
    @include font(35);
    font-weight: 600;
    white-space: normal;
    min-height: 135px;
  }
  &::part(icon-right) {
    width: 100%;
    font-weight: 400;
    @include font(13);
    border-top: 1px solid var(--gx-color-stroke);
    padding-top: 19px;
    white-space: normal;
    text-align: left;
  }
  &:hover {
    &::part(base) {
      background-color: var(--gx-color-white);
    }
  }
  &[active]:not([active='false']) {
    &::part(base) {
      border-color: var(--gx-color-blue);
      background-color: var(--gx-color-blue);
    }
    &::part(icon-left) {
      color: var(--gx-color-white);
    }
    &::part(label) {
      color: var(--gx-color-white);
    }
    &::part(icon-right) {
      color: var(--gx-color-white);
    }
  }
}
