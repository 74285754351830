.radio_default {
  --gx-color-primary-50: var(--gx-color-blue);
  --gx-input-border-color: var(--gx-color-stroke);
  --gx-toggle-size: 20px;
  --gx-input-font-size-medium: 14px;
  &::part(base) {
    &:hover {
      --gx-input-border-color: var(--gx-color-input-hover);
    }
  }
  &::part(control) {
    box-shadow: none;
  }
}
