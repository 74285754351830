@import '../../../styles/variables/index.scss';

.project_icon {
  display: none;
  @include mobile {
    width: 35px;
    @include flex(cc);
    z-index: 1;
    border-radius: 100%;

    &[data-active-notify="true"] {
      top:70px;
    }

    & > gx-icon {
      margin-right: 2px;
      --icon-height: 25px;
      --icon-width: 25px;
      color: var(--gx-color-blue);
    }

  }
}