@import '../../../styles/variables/index.scss';

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile {
  &_card {
    td {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    position: relative;
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--gx-color-stroke);
    border-bottom: 1px solid var(--gx-color-stroke);
    display: flex;
    flex-direction: column;
    &:hover {
      background-color: var(--gx-color-list-hover);
    }

    &_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &--right {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }
    }
    &_title {
      font-weight: 600;
      font-size: 16px;
    }
    &_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_type {
        display: flex;
        align-items: center;
        &_image {
          margin-left: 5px;
        }
      }
      &_created {
        &--text {
          color: var(--gx-color-gray-text);
        }
      }
      &_right {
        margin-left: 20px;
      }
    }
  }
  &_panel {
    display: none;
    @include laptop-xs {
      display: flex;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 86px;
    max-height: 86px;
    background-color: var(--gx-color-white);
    padding: 15px 10px 35px 10px;
    z-index: 10;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 600;
    border-top: 1px solid var(--gx-color-lightgray);
    filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05));
    will-change: transform;
    transform: translateY(100%);
    transition: 0.4s ease-out;
    &[data-show='true'] {
      transform: translateY(0);
    }
    &_bnt {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 40px;
      width: 80px;
      &--delete {
        color: var(--gx-color-red);
      }
      &--close {
        color: var(--gx-color-gray-text);
        gx-icon {
          color: var(--gx-color-gray-text);
        }
      }
    }
  }
}
.table {
  width: 100%;
  border-collapse: collapse;

  &_wrap {
    margin-bottom: 20px;
    max-width: 100%;
    overflow: visible;
    overflow-x: auto;
    scrollbar-color: var(--gx-color-input-hover) transparent;
    scrollbar-width: thin;
    @include scrollBar;
  }

  &_row {
    transition: all 0.2s linear;
    border-bottom: 1px solid var(--gx-color-stroke);
    cursor: pointer;

    &:hover {
      background-color: var(--gx-color-list-hover);
    }

    @include laptop-xs {
      padding: 20px;
      border: 1px solid var(--gx-color-stroke);
      border-radius: 10px;
      position: relative;
    }

    @media screen and (max-width: 620px) {
      border: none;
      border-bottom: 1px solid var(--gx-color-stroke);
      border-radius: 0;
      display: flex;
    }
  }

  &_head {
    position: relative;
    border-bottom: 1px solid var(--gx-color-stroke);

    @include laptop-xs {
      display: none;
    }

    &__btn {
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translate(0, -50%);
    }

    &__icon {
      width: 20px;
      height: 20px;
      color: var(--gx-color-red);
    }

    &__checkbox {
      position: absolute;
      left: 5px;
      top: 15px;
    }

    &__row {
      border-bottom: 1px solid var(--gx-color-stroke);
    }

    &__item {
      padding: 13px 20px;
      text-align: left;

      &:first-child {
        width: 400px;
        padding-left: 40px;
        position: relative;
      }

      &:last-child {
        padding: 11px;
        width: max-content;
      }

      &-delete {
        &:first-child {
          padding-left: 90px;
        }
      }
    }
  }

  &_body {
    @include laptop-xs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    @include tablet-md {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &_data {
    &__item {
      padding: 4px 20px;
      height: 54px;

      &:not([data-type='dropdown']) {
        opacity: 0;
        transition: 0.4s ease-out;
        -moz-animation-duration: 0.4s;
        -webkit-animation-duration: 0.4s;
        -moz-animation-name: fadeInDown;
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
      }

      &:first-child {
        width: 400px;
      }

      &:last-child {
        padding: 0px;
        width: 0;
      }

      @include laptop-xs {
        display: block;
        text-align: right;
        height: auto;
        &:first-child {
          padding-top: 50px;
          width: auto;
        }

        &:last-child {
          position: absolute;
          width: auto;
          padding: 5px;
          top: 4px;
          right: 10px;
        }

        &::before {
          content: attr(data-label);
          float: left;
          display: block;
          text-align: left;
          max-width: 150px;
          word-break: break-word;
        }
      }

      @media screen and (max-width: 620px) {
        display: none;

        &:first-child {
          max-width: 550px;
          width: 100%;
          display: inline-block;
          text-align: left;
          padding: 13px 20px 13px 0;
        }

        &:last-child {
          display: inline-block;
          position: static;
          width: auto;
          padding: 0;
        }

        &::before {
          display: none;
        }
      }
      &-promo {
        &:first-child {
          width: 300px;
        }
        &:last-child {
          text-align: right;
          font-weight: 600;
          width: 200px;
        }
        @include laptop-md {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px 0;

          &:nth-child(odd) {
            justify-content: right;
          }
          &:first-child {
            justify-content: left;
            width: auto;
            text-align: left;
            font-weight: 600;
            &::before {
              display: none;
            }
          }
          &:last-child {
            padding: 10px 0;
            width: 100%;
          }
          &::before {
            content: attr(data-label) ':';
            display: inline-block;
            margin-right: 10px;
            word-wrap: none;
            @include font(14);
            font-weight: 400;
            text-align: left;
            color: var(--gx-color-overlay);
          }
        }

        @include laptop-xs {
          &:first-child {
            padding-top: 10px;
          }
          &:last-child {
            position: static;
            padding: 10px 0;
            width: 100%;
          }
          &::before {
            max-width: none;
          }
        }
        @include tablet-lg {
          &:nth-child(odd) {
            justify-content: left;
          }
        }
        @include ipad {
          @include font(14);
        }
        @media screen and (max-width: 620px) {
          &:first-child {
            text-align: left;
            padding: 10px 0 10px;
          }

          &:last-child {
            text-align: left;
            width: auto;
            padding: 10px 0;
          }
        }
        @include mobile {
          &::before {
            @include font(13);
          }
        }
      }
    }

    &__more_icon {
      @include square-icon(24);
    }

    &__dropdown {
      &_btn {
        margin: 11px;
        @include laptop-xs {
          margin: 5px;
        }
        @media screen and (max-width: 620px) {
          margin: 11px;
        }
      }
      &_icon {
        @include square-icon(24);
        color: var(--gx-color-primary2);

        &--danger {
          color: var(--gx-color-red);
        }
      }
    }
  }
}

.table_sort {
  &__icon {
    &_up,
    &_down {
      width: 10px;
      height: 7px;
      display: block;
    }

    &_up {
      margin-bottom: 1px;
    }
  }
}

.cargo_space {
  &__icon {
    --icon-width: 38px;
    --icon-height: 22px;
    color: var(--gx-color-primary2);
  }
}
