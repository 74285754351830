@import '../../../styles/variables/index.scss';

.search {
  &__icon {
    @include square-icon(24);
  }
  &__closeIcon {
    @include square-icon(20);
    cursor: pointer;
    margin-right: 10px;
  }
}
