.pagination {
  --gx-input-height-medium: 32px;
  --gx-input-border-radius-medium: 6px;
  --gx-spacing-large: 0;

  --gx-color-gray-80: transparent;
  --gx-color-gray-40: var(--gx-color-blue);
  --gx-color-primary-95: var(--gx-color-white);
  --gx-color-primary-80: var(--gx-color-blue);
  --gx-color-primary-70: var(--gx-color-blue);
  --gx-color-primary-40: var(--gx-color-blue);
  --gx-color-primary-30: var(--gx-color-blue);
  --gx-color-primary-50: var(--gx-color-blue);
  &::part(base) {
    min-width: 32px;
    @include flex(cc);
    box-shadow: none;
    background-color: transparent;
  }
  // &[active],
  &[active='true'] {
    --gx-color-gray-80: var(--gx-color-blue);
  }
  &[disabled],
  &[disabled='true'] {
    --gx-color-gray-40: var(--gx-color-stroke);
    &::part(base) {
      opacity: 1;
    }
  }
}
