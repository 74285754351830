.table_sort {
  --gx-spacing-large: 0;
  --gx-color-primary-50: var(--gx-color-primary2);
  --gx-color-primary-60: var(--gx-color-primary);
  --gx-color-primary-40: var(--gx-color-primary);
  --gx-font-weight-semibold: normal;
  &::part(base) {
    height: auto;
    white-space: normal;
    box-shadow: none;
    @include mobile {
      height: max-content;
    }
  }
  &::part(icon-right) {
    margin-left: 5px;
    color: var(--gx-color-primary2);
  }
  &::part(label) {
    @include mobile {
      white-space: normal;
    }
  }

  &[data-up]:not([data-up='false']) {
    :global {
      gx-icon[class*='table_sort__icon_up'] {
        color: var(--gx-color-blue);
      }
    }
  }
  &[data-down]:not([data-down='false']) {
    :global {
      gx-icon[class*='table_sort__icon_down'] {
        color: var(--gx-color-blue);
      }
    }
  }
}

.table_no_sort {
  --gx-spacing-large: 0;
  --gx-color-primary-50: var(--gx-color-primary2);
  --gx-color-primary-60: var(--gx-color-primary);
  --gx-color-primary-40: var(--gx-color-primary);
  --gx-font-weight-semibold: normal;
  pointer-events: none;
  &::part(base) {
    box-shadow: none;
    @include mobile {
      height: max-content;
    }
  }
  &::part(label) {
    @include mobile {
      white-space: normal;
    }
  }
}
