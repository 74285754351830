@import '../../../styles/variables/index.scss';

.type {
  @include flex('lc');
  padding: 30px;
  background: var(--gx-color-white);
  box-shadow: 3px 3px 40px rgba(35, 36, 50, 0.08);
  position: relative;

  @include laptop-xs {
    justify-content: space-around;
    align-items: center;
    padding: 30px;
    height: auto;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
  &__edit_profile{
    display: none;
    position: absolute;
    top: 25px;
    right: 25px;
    &::part(base){
      width:30px;
      height: 30px;
      @include flex(cc);
    }
    gx-icon{    
      color: var(--gx-color-blue);
      --icon-width: 24px;
      --icon-height: 24px;
    }
    @include tablet-lg{
      display: block;

    }
  }

  &__info {
    margin-left: 20px;
    flex-grow: 1;
    @include mobile{
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &__labels {
    width: 100%;
    margin-bottom: 22px;

    &__blockLabels {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include laptop-xs {
        justify-content: flex-start;
        gap: 20px;
      }
    }

    &_title {
      position: relative;
      padding: 0 0 0 30px;
      margin: 0;
      font-weight: 400;
      @include font(16);
      color: var(--gx-color-primary);
    }

    &_icon {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 24px;
      height: 24px;
      color: var(--gx-color-blue);
    }

    &_icon-confirmed {
      position: relative;
      width: 30px;
      height: 30px;
      color: var(--gx-color-blue);
      align-self: flex-start;

      @include laptop-xs {
        align-self: center;
        width: 25px;
        height: 25px;
      }
    }

    &_icon-yellow {
      color: var(--gx-color-yellow);
    }

    &_value {
      padding: 0;
      margin: 0;
      font-weight: 600;
      @include font(28);
      color: var(--gx-color-primary);

      @include mobile {
        @include font(24);
      }
    }
  }

  &__buttons {
    width: 100%;

    &_partner {
      &::part(base){
        color: var(--gx-color-blue);
        font-weight: 600;
      }
    }
  }

  &__button {
    &::part(base) {
      width: 100%;
    }
  }
}
