@keyframes openAccordin {
  0% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
}

.accordion {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--gx-animation-accordion) cubic-bezier(0, 1, 0, 1);

  &[data-open="true"] {
    max-height: 1000px;
    overflow: visible;
    transition: max-height var(--gx-animation-accordion) ease-in-out;
    animation: openAccordin;
    animation-duration: 2s;
  }
}
